














import { Vue, Component } from 'vue-property-decorator';

@Component({
	components: {
		VideoPlayer: () => import('../components/VideoPlayer.vue')
	}
})
export default class Tutorial extends Vue {}
